import { Controller } from '@hotwired/stimulus';
import SignaturePad from 'signature_pad';

let signaturePad;
export default class extends Controller {
  static targets = ['canvas', 'hiddenSignatureData', 'errorEmpty', 'errorSmall', 'firstName', 'lastName', 'role'];

  connect() {
    this.initCanvas();
    this.initSignaturePad();
    this.canvasChanged = false;
  }

  initCanvas() {
    const canvas = this.canvasTarget;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
  }

  initSignaturePad() {
    const canvas = this.canvasTarget;
    const hiddenField = this.hiddenSignatureDataTarget;

    signaturePad = new SignaturePad(canvas);

    signaturePad.addEventListener('endStroke', () => {
      this.canvasChanged = true;
      hiddenField.value = signaturePad.toDataURL();
      this.hideValidationError();
    });

    if (hiddenField.value !== '') {
      signaturePad.fromDataURL(hiddenField.value);
    }

    this.bindSubmit(signaturePad);
  }

  clearSignature(event) {
    event.preventDefault();
    signaturePad.clear();
    this.hiddenSignatureDataTarget.value = '';
    this.hideValidationError();
  }

  // eslint-disable-next-line no-shadow
  bindSubmit(signaturePad) {
    document.querySelector('input[type="submit"]').addEventListener('click', (event) => {
      this.onSubmit(event, signaturePad);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  changeActiveTab(event) {
    const tabID = event.target.dataset.tab;
    let element = event.target;
    while (element.nodeName !== 'BUTTON') {
      element = element.parentNode;
    }
    const ulElement = element.parentNode.parentNode;
    const aElements = ulElement.querySelectorAll('li > button');
    const tabContents = document.getElementById('tabs-id').querySelectorAll('.tab-content > div');

    for (let i = 0; i < aElements.length; i++) {
      aElements[i].classList.remove('text-white');
      aElements[i].classList.remove('bg-blue-600');
      aElements[i].classList.add('text-black-600');
      aElements[i].classList.add('bg-white');
      tabContents[i].classList.add('hidden');
      tabContents[i].classList.remove('block');
    }
    element.classList.remove('text-blue-600');
    element.classList.remove('bg-white');
    element.classList.add('text-white');
    element.classList.add('bg-blue-600');
    document.getElementById(tabID).classList.remove('hidden');
    document.getElementById(tabID).classList.add('block');
  }

  // eslint-disable-next-line no-shadow, class-methods-use-this
  hasSmallSignature(signaturePad) {
    if (signaturePad.isEmpty() || !this.canvasChanged) {
      return false;
    }

    const groups = signaturePad.toData();
    const totalPoints = groups.reduce((total, group) => total + group.points.length, 0);

    return totalPoints < 5;
  }

  // eslint-disable-next-line no-shadow
  onSubmit(event, signaturePad) {
    const firstName = this.hasFirstNameTarget && this.firstNameTarget.value;
    const lastName = this.hasLastNameTarget && this.lastNameTarget.value;
    const signatureWithNameEmpty = !(firstName && lastName);
    const signatureWithPadEmpty = signaturePad.isEmpty();
    const signatureTooSmall = this.hasSmallSignature(signaturePad);
    const emptySignature = signatureWithNameEmpty && signatureWithPadEmpty;
    const otherRole = this.roleTarget.dataset.role === 'other';
    const required = this.element.dataset.required === 'true';

    if (required && (emptySignature || signatureTooSmall) && !otherRole) {
      this.showValidationError(signatureTooSmall ? 'small' : 'empty');
      event.preventDefault();
    } else {
      this.hideValidationError();
    }
  }

  showValidationError(target) {
    if (target === 'small') {
      this.errorSmallTarget.classList.remove('hidden');
    } else {
      this.errorEmptyTarget.classList.remove('hidden');
    }
  }

  hideValidationError() {
    this.errorEmptyTarget.classList.add('hidden');
    this.errorSmallTarget.classList.add('hidden');
  }
}
